.container {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container li a {
  padding: 10px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  cursor: pointer;
}

.activeLink a {
  padding: 6px 13px !important;
  color: #ffffff;
  background: #4e6bee;
  border-radius: 50%;
}
