* {
  box-sizing: border-box;
  font-family: 'SFProDisplay';
}

body {
  margin: 0;
  font-family: 'SFProDisplay';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'SFProDisplay', monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input {
  border: none;
  font-family: 'Quicksand';
}

button,
input,
input:focus {
  outline: none;
}

button,
button:disabled {
  border: none;
  border: none;
}

textarea {
  resize: none;
  outline: none;
  font-family: 'SFProDisplay';
}

a {
  text-decoration: none;
}

span {
  font-family: 'SFProDisplay';
}

.rdrDefinedRangesWrapper {
  display: none;
}
.rdrDateDisplayWrapper {
  display: none;
}
.rdrMonthAndYearPickers {
  justify-content: space-around !important;
}
.rdrMonthPicker > select {
  color: #000;
  font-weight: 700;
  font-size: 22px;
  line-height: 27.5px;
  font-family: 'Quicksand';
  letter-spacing: -0.24px;
}
.rdrYearPicker > select {
  color: #000;
  font-weight: 700;
  font-size: 22px;
  line-height: 27.5px;
  font-family: 'Quicksand';
  letter-spacing: -0.24px;
}
.rdrMonthName {
  display: none;
}
.rdrWeekDay {
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.6px;
  font-size: 14px;
  font-family: 'SFProDisplay';
  color: #4f4f4f;
}
.rdrDayNumber {
  position: relative;
  font-weight: 600;
  font-family: 'SFProDisplay';
  letter-spacing: 0.6px;
  color: #bdbdbd;
  line-height: 19px;
}
.rc-slider-handle {
  border: solid 2px #000 !important;
  background-color: #000 !important;
}
.rc-slider-dot-active {
  border-color: #bdbdbd !important;
}
.rc-slider-track {
  background-color: #bdbdbd !important;
}
.rc-slider-dot {
  bottom: -3px !important;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url(./assets/fonts/Quicksand-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'SFProDisplay';
  src: local('SFProDisplay'),
    url(./assets/fonts/SFProDisplay-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'SFProDisplay';
  src: local('SFProDisplay'),
    url(./assets/fonts/SFProDisplay-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'SFProDisplay';
  src: local('SFProDisplay'),
    url(./assets/fonts/SFProDisplay-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'SFProDisplay';
  src: local('SFProDisplay'),
    url(./assets/fonts/SFProDisplay-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'SFProDisplay';
  src: local('SFProDisplay'),
    url(./assets/fonts/SFProDisplay-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
