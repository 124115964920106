.activityContainer {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.activityContainer li {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  cursor: pointer;
  margin: 0 0.25rem;
  padding: 0.4375rem 0 !important;
}

.activityActiveLink {
  color: #ffffff;
  background: #000000;
  border-radius: 50%;
}

.activityDisabledButton {
  opacity: 0.3;
}

.activityListItem > a {
  /* padding: 0.3125rem 0.625rem; */
  padding: 0 0.75rem;
}

.activityNextButton {
  margin-top: 0.25rem;
  margin-left: 0.75rem;
}
.activityPrevButton {
  margin-top: 0.25rem;
  margin-right: 0.75rem;
}
